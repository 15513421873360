export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAHSpKRfLnsgJFlCJmjKDizyUke1d3Gi-E",
    authDomain: "sza-preprod.firebaseapp.com",
    projectId: "sza-preprod",
    storageBucket: "sza-preprod.appspot.com",
    messagingSenderId: "359616739836",
    appId: "1:359616739836:web:a4649c99522fe15a221a79",
    measurementId: "G-ZV3JBBJH54"
  },
  tenantId: "dev-sophia-5s573",
  clientId: '4b9b9ab5b734408d915ec31751bbf114',
  baseURL: "https://dev.api.sophia.seiza-ti.cl/api/v1/",
  endpoint: {
    modal_360: "modal-360/historial"
  }
};
