import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { IRespAnamnesis } from '../../interfaces/anamnesis.interface';

@Injectable({
  providedIn: 'root',
})
export class AnamnesisService {
  private clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

  guardar(data: any): Observable<any> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.post<any>(
      environment.baseURL + 'ficha-clinica/anamnesis',
      data,
      {
        headers,
        params,
      }
    );
  }

  obtener(idAgendamiento: string): Observable<IRespAnamnesis> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespAnamnesis>(
      `${environment.baseURL}ficha-clinica/anamnesis/${idAgendamiento}`,
      {
        headers,
        params,
      }
    );
  }
}
