import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {Auth} from "@angular/fire/auth";

@Component({
  selector: 'soph-modal-error-general',
  standalone: true,
  imports: [CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule],
  templateUrl: './modal-error-general.component.html',
  styleUrl: './modal-error-general.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalErrorGeneralComponent {
  private auth = inject(Auth);
  constructor(private router: Router,
              private dialogRef: MatDialogRef<ModalErrorGeneralComponent>) {
  }
  volverAlogin(){
    this.dialogRef.close();
    this.router.navigate(['/login']); // redireccionamiento login
  }

}
