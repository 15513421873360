<mat-form-field
  [formGroup]="formGroup"
  subscriptSizing="dynamic"
  [floatLabel]="placeholder ? 'always' : 'auto'"
  appearance="outline"
>
  <mat-icon matPrefix>search</mat-icon>

  @if(label) {
  <mat-label>{{ label }}</mat-label>
  }

  <input [formControlName]="controlName" hidden />

  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [formControl]="searchCtrl"
    (ngModelChange)="onChangeInput($event)"
    [matAutocomplete]="auto"
    [required]="isRequired(controlName)"
  />

  <mat-icon matSuffix>arrow_drop_down</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="selected($event)"
  >
    @for (value of filteredValues(); track value) {
    <mat-option [value]="value[idField]">{{ value[viewField] }}</mat-option>
    }
  </mat-autocomplete>

  @if (isInvalid(controlName) && errorMessage()[controlName]) {
  <mat-error>{{ errorMessage()[controlName] }}</mat-error>
  }
</mat-form-field>
