import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../modal-confirm/modal-confirm.component';
import { SpeechToTextComponent } from '../speech-to-text/speech-to-text.component';

@Component({
  selector: 'soph-textarea-with-speech-to-text-form-field',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './textarea-with-speech-to-text-form-field.component.html',
  styleUrl: './textarea-with-speech-to-text-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaWithSpeechToTextFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() maxLength!: number;
  @Input() errorMessages!: ErrorMessages;

  readonly dialog = inject(MatDialog);
  readonly validatorMaxLength = Validators.maxLength;

  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }

  /* Actions */
  public openConfirmSpeechToText() {
    const inputContainText: boolean = !!this.control?.value;

    if (inputContainText) {
      const dialogRef = this.dialog.open(ModalConfirmComponent, {
        autoFocus: false,
        panelClass: 'custom-confirmation-dialog',
        width: '452px',
        data: {
          type: ModalConfirmType.WARNING,
          description:
            '¿Confirmas que deseas sobreescribir el texto existente?',
          config: {
            buttons: [
              { title: 'No', action: false },
              { title: 'Si', action: true },
            ],
          },
        } as ModalConfirmProps,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (typeof result === 'boolean') {
          this.openSpeechToText(result);
        }
      });
    } else {
      this.openSpeechToText();
    }
  }

  private openSpeechToText(clear: boolean = false) {
    const dialogRef = this.dialog.open(SpeechToTextComponent, {
      autoFocus: false,
      panelClass: 'custom-speech-dialog',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.transcript) {
        this.control.setValue(
          clear ? data.transcript : (this.control.value ?? '') + data.transcript
        );
      }
    });
  }
}
