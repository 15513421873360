import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum SnackInfoType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export interface SnackInfoProps {
  message: string;
  type: SnackInfoType;
  config?: {
    duration?: number;
  };
}

@Component({
  selector: 'soph-snack-info',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatIcon,
  ],
  templateUrl: './snack-info.component.html',
  styleUrl: './snack-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackInfoComponent {
  // Inyectar la referencia del snackbar y los datos
  readonly snackBarRef = inject(MatSnackBarRef);

  // Config
  readonly icon = signal<string>('');
  readonly iconRegistry = inject(MatIconRegistry);
  readonly sanitizer = inject(DomSanitizer);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackInfoProps) {
    this.initConfig();
  }

  private initConfig() {
    this.snackBarRef.containerInstance.snackBarConfig.duration =
      this.data.config?.duration ?? 3000;

    switch (this.data.type) {
      case SnackInfoType.SUCCESS:
        this.icon.set('check_circle');
        this.snackBarRef.containerInstance.snackBarConfig.panelClass =
          'snackbar-success';
        break;
      case SnackInfoType.WARNING:
        this.icon.set('alert_cirlce');
        this.snackBarRef.containerInstance.snackBarConfig.panelClass =
          'snackbar-warning';
        break;
      case SnackInfoType.ERROR:
        this.icon.set('cancel_circle');
        this.snackBarRef.containerInstance.snackBarConfig.panelClass =
          'snackbar-error';
        break;
      default:
        this.icon.set('info_circle');
        this.snackBarRef.containerInstance.snackBarConfig.panelClass =
          'snackbar-info';
    }
  }

  // Método para cerrar el snackbar
  closeSnackbar() {
    this.snackBarRef.dismiss();
  }
}
