
<mat-dialog-content class="modal-content">
  <mat-icon>cancel</mat-icon>
  <h2>Tuvimos un inconveniente en nuestra<br> plataforma, Por favor, espere unos <br>segundos e intente nuevamente.</h2>
</mat-dialog-content>

<mat-dialog-actions class="modal-actions">
  <button
    class="btn-cancelar"
    (click)="volverAlogin()"
    mat-flat-button>Aceptar</button>
</mat-dialog-actions>
