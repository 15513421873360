import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { IRespAlergias } from '../interfaces/alergias.interface';
import { IRespCirugias } from '../interfaces/cirugias.interface';
import { IRespEnfermedades } from '../interfaces/enfermedades.interface';
import { IRespEspecialidades } from '../interfaces/especialidades.interface';
import { IRespMedicamentos } from '../interfaces/tratamiento/medicamentos.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormAnamnesisService {
  private endpointUrlBase = `${environment.baseURL}datos-medicos`;
  private clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

  getEspercialidades(): Observable<IRespEspecialidades> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespEspecialidades>(
      this.endpointUrlBase + '/especialidades',
      {
        headers,
        params,
      }
    );
  }

  getAlergias(): Observable<IRespAlergias> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespAlergias>(
      this.endpointUrlBase + '/alergias',
      {
        headers,
        params,
      }
    );
  }

  getEnfermedades(): Observable<IRespEnfermedades> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespEnfermedades>(
      this.endpointUrlBase + '/enfermedades',
      {
        headers,
        params,
      }
    );
  }

  getCirugias(): Observable<IRespCirugias> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespCirugias>(
      this.endpointUrlBase + '/cirugias',
      {
        headers,
        params,
      }
    );
  }

  getMedicamentos(): Observable<IRespMedicamentos> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespMedicamentos>(
      this.endpointUrlBase + '/medicamentos',
      {
        headers,
        params,
      }
    );
  }
}
