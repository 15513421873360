export enum TConsulta {
  Cpresencial = 'Presencial (Centro de salud)',
  CTelemedicina = 'Telemedicina',
  Cdomiciliaria = 'Visita Domiciliaria',
}

export enum EstadoTratamiento {
  INICIAL, // btn guardar activado
  GUARDADO, // btn guardar oculto, btn editar visible
  EN_EDICION, // btn editar oculto, btn guardar deshabilitado hasta que se haga un cambio
}

export enum EstadoFichaClinica {
  REALIZADA = 'REALIZADA',
  ANULADA = 'ANULADA',
  SUSPENDIDA = 'SUSPENDIDA',
  AGENDADA = 'AGENDADA',
  PROCESO = 'PROCESO',
}

export enum TipoReporteResumenClinico {
  RecetaMedica = 'receta-medica',
  InformeMedico = 'informe-medico',
  Terapias = 'terapias',
  Examenes = 'examenes',
}
