import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export enum ModalConfirmType {
  SUCCESS,
  WARNING,
  INFO,
  ERROR,
}

export interface ModalConfirmProps {
  type: ModalConfirmType;
  description: string;
  message?: string;
  config: {
    buttons: Array<{
      title: string;
      action: boolean;
    }>;
  };
}

@Component({
  selector: 'soph-generic-confirmation',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './modal-confirm.component.html',
  styleUrl: './modal-confirm.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  public data = inject(MAT_DIALOG_DATA) as ModalConfirmProps;

  constructor(public dialogRef: MatDialogRef<ModalConfirmComponent>) {}

  public getIconByType(type: ModalConfirmType): string {
    switch (type) {
      case ModalConfirmType.SUCCESS:
        return 'check_circle';
      case ModalConfirmType.INFO:
        return 'info';
      case ModalConfirmType.WARNING:
        return 'error';
      case ModalConfirmType.ERROR:
        return 'report';
      default:
        return 'info';
    }
  }

  public close(value: boolean | null = null) {
    this.dialogRef.close(value);
  }
}
