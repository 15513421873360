<mat-form-field
  class="estilo-input"
  appearance="outline"
  subscriptSizing="dynamic"
  [floatLabel]="placeholder ? 'always' : 'auto'"
>
  <mat-label>{{ label }}</mat-label>

  <textarea
    matInput
    [formControl]="control"
    [placeholder]="placeholder || ''"
    [required]="control.hasError('required')"
  ></textarea>

  @if(maxLength) {
  <mat-hint align="end">
    {{ control.value?.length ?? 0 }} / {{ maxLength }}
  </mat-hint>
  }

  <div matSuffix class="speech-text-action">
    @if(!control.disabled) {
    <button
      mat-icon-button
      matTooltip="Dictado por voz"
      type="button"
      (click)="openConfirmSpeechToText()"
      [disabled]="control.disabled"
    >
      <mat-icon svgIcon="mic"></mat-icon>
    </button>
    } @if(control.value && !control.disabled) {
    <button
      mat-icon-button
      matTooltip="Limpiar"
      type="button"
      (click)="control.reset()"
    >
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
    }
  </div>
  @if (hasError) {
  <mat-error>{{ errorMessage }}</mat-error>
  }
</mat-form-field>
