import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'soph-speech-to-text',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  templateUrl: './speech-to-text.component.html',
  styleUrl: './speech-to-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechToTextComponent {
  readonly dialogRef = inject(MatDialogRef<SpeechToTextComponent>);

  public isRecording = signal(false);
  public isPaused = signal(false);
  public isListening = signal(false);
  public transcript: string = '';

  recognition: any;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    const { webkitSpeechRecognition } = window as any;
    this.recognition = new webkitSpeechRecognition();
    this.recognition.lang = 'es-ES';
    this.recognition.interimResults = false;
    this.recognition.maxAlternatives = 1;

    this.recognition.onstart = (event: any) => {
      this.isListening.set(true);
      console.info('Restarting speech recognition started...');
    };

    this.recognition.onresult = (event: any) => {
      this.transcript += event.results[0][0].transcript + ' ';
      this._changeDetectorRef.detectChanges();
    };

    this.recognition.onerror = (event: any) => {
      console.error('Speech recognition error detected: ' + event.error);
    };

    this.recognition.onend = () => {
      this.isListening.set(false);

      if (this.isRecording() && !this.isPaused()) {
        console.info('Restarting speech recognition due to inactivity...');
        this.recognition.start();
      }
    };
  }

  startRecording() {
    this.isRecording.set(true);
    this.isPaused.set(false);
    this.recognition.start();
  }

  stopRecording(sendResult: boolean = false) {
    this.isRecording.set(false);
    this.isPaused.set(false);
    this.recognition.stop();

    this.sendText(sendResult);
  }

  pauseRecording() {
    if (this.isRecording()) {
      this.isPaused.set(true);
      this.recognition.stop();
    }
  }

  resumeRecording() {
    if (this.isPaused()) {
      this.isPaused.set(false);
      this.recognition.start();
    }
  }

  private sendText(send: boolean) {
    this.dialogRef.close(send ? { transcript: this.transcript ?? null } : null);
  }
}
