import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  model,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { MatIconModule } from '@angular/material/icon';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { distinctUntilChanged } from 'rxjs';

export interface Value {
  [key: string]: any;
}

@Component({
  selector: 'soph-select-search-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  templateUrl: './select-search-form-field.component.html',
  styleUrl: './select-search-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchFormFieldComponent<T extends Value> {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() values!: WritableSignal<T[]>;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() errorMessages!: ErrorMessages;
  @Input() limitValues!: number;
  @Input() idField!: keyof T;
  @Input() viewField!: keyof T;

  readonly searchCtrl = new FormControl<string>('');
  readonly currentValue = model('');

  readonly filteredValues = computed(() => {
    const currentValue = this.currentValue()
      ? this.currentValue().toLowerCase()
      : '';

    const filtered = currentValue
      ? this.values().filter((value) =>
          value[this.viewField].toLowerCase().includes(currentValue)
        )
      : this.values();

    return typeof this.limitValues === 'number' && this.limitValues > 0
      ? filtered.slice(0, this.limitValues)
      : filtered.slice();
  });

  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  @ViewChild(MatAutocomplete, { static: false })
  matAutoComplete!: MatAutocomplete;

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.currentValue.set(value || '');
      });

    this.formGroup.get(this.controlName)?.statusChanges.subscribe((state) => {
      if (state === 'DISABLED') {
        this.searchCtrl.disable();
      } else {
        this.searchCtrl.enable();
      }
    });

    this.formGroup.controls[this.controlName].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          const selMatOption = this.matAutoComplete.options
            .toArray()
            .find((o) => o.value === value);

          selMatOption?.select();
        } else {
          setTimeout(() => {
            this.matAutoComplete.options.toArray().forEach((o) => o.deselect());
          });

          this.currentValue.set('');
        }
      });
  }

  public displayFn(value: any): string {
    const objValue = this.values().find((v) => v[this.idField] === value);

    const finalValue = objValue ? objValue![this.viewField] : value ?? '';

    return finalValue;
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;

    this.formGroup.controls[this.controlName].setValue(value ?? null);

    const valueFinded = this.values().find((v) => v[this.idField] === value);

    if (valueFinded) {
      this.currentValue.set(valueFinded[this.viewField]);
    }
  }

  public onChangeInput(event: any): void {
    const value = event;

    const valueFinded = this.values().find((v) => v[this.idField] === value);

    this.formGroup.controls[this.controlName].setValue(
      valueFinded ? valueFinded[this.idField] : null
    );
  }

  public updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    const currentErrorMessages = this.errorMessage() as any;

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  public isRequired(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  public isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    return control?.invalid ?? false;
  }
}
