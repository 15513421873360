<div class="dialog-container">
  <section class="section-close">
    <button mat-icon-button (click)="stopRecording()">
      <mat-icon>close</mat-icon>
    </button>
  </section>

  <mat-dialog-content class="section-content">
    <div class="recording">
      <button
        mat-icon-button
        (click)="startRecording()"
        class="recording__button"
        [disabled]="isRecording()"
      >
        <mat-icon>mic</mat-icon>
      </button>

      @if(isRecording()){
      <section class="recording__indicator">
        <mat-progress-bar
          [mode]="isPaused() ? 'determinate' : 'buffer'"
        ></mat-progress-bar>

        <div class="recording__indicator__count">
          <mat-icon>{{
            isPaused() ? "pause" : "radio_button_checked"
          }}</mat-icon
          ><span>{{ isPaused() ? "En pausa" : "Escuchando" }}</span>
        </div>
      </section>
      } @else {
      <p>Presiona el microfono para comenzar a grabar</p>
      }
    </div>

    @if(isRecording()){
    <div class="section-action">
      <button
        mat-flat-button
        (click)="isPaused() ? resumeRecording() : pauseRecording()"
      >
        {{ isPaused() ? "Reanudar" : "Pausar" }}
      </button>
      <button
        mat-stroked-button
        (click)="stopRecording(true)"
        [disabled]="!isListening() && !transcript"
      >
        Finalizar
      </button>
    </div>
    }
  </mat-dialog-content>
</div>
