<h2 mat-dialog-title>
  <mat-icon [class]="getIconByType(data.type) + '-icon'">
    {{ getIconByType(data.type) }}
  </mat-icon>
</h2>

<mat-dialog-content>
  <p>{{ data.description }}</p>

  @if(data.message) {
  <span>{{ data.message }}</span>
  }

  <mat-dialog-actions>
    @for(btn of data.config.buttons; track btn) {
    <button
      mat-flat-button
      [ngClass]="!btn.action ? 'estilo-button' : null"
      (click)="close(btn.action)"
    >
      {{ btn.title }}
    </button>
    }
  </mat-dialog-actions>
</mat-dialog-content>
